import React from 'react';

import { Link } from 'gatsby';

import '../scss/404.scss';

const NotFoundPage = () => {
  return (
    <div>
      <div className="not-found">
        <img
          src="/images/website-search.svg"
          alt="computer with magnifying glass"
        />
        <h2>404</h2>
        <p>The page you are looking for is missing or may have been moved.</p>
        <Link to="/" className="white-button">
          Back to home
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
